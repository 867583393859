import React from "react";
import { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SubscriptApi } from "../Apis/AuthApis";
import "../Styles/footer.css";

function Footer() {
  const navigation =useNavigate()
const [email, setEmail] = useState('')

  const handleSubscribe=()=>{
    SubscriptApi(email, setEmail)
  }
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">

            <div className="col-md-2 mt-3 mt-sm-3 mt-0 ">
              <div className="listing">
                <h5 className="text-white">Services & Products</h5>
                <ul className="m-0 p-0">
                  <li><a  onClick={()=>navigation("/digital-banking")}>Digital Banking</a></li>
                  <li><a  onClick={()=>navigation("/bill-payments-transfers")}>Bill Payments Transfers</a></li>
                  <li><a  onClick={()=>navigation("/fixed-deposits")}>Fixed Deposits</a></li>
                  <li><a  onClick={()=>navigation("/savings-accounts")}>Savings Accounts</a></li>
                  <li> <a  onClick={()=>navigation("/zeusBank-share-holding-account")}>ZeusBank Shareholding Account</a></li>
                  <li><a  onClick={()=>navigation("/digital-debit-cards")}>Digital Debit Cards</a></li>
                  <li><a  onClick={()=>navigation("/digital-credit-cards")}>Digital Credit Cards</a></li>

                  <li><a  onClick={()=>navigation("/emergency-lending-facilities")}>Emergency Lending Facilities</a></li>
                  <li><a  onClick={()=>navigation("/university-tuition-loans")}>University Tuition Loans</a></li>
                  <li><a  onClick={()=>navigation("/rewards-on-your-payZeus-purchases")}>Rewards on Your PayZeus Purchases</a></li>
                </ul>
              </div>
            </div>

            <div className="col-md-2 mt-3 mt-sm-3 mt-0 ">
              <div className="listing">
                <h5 className="text-white ">Open Account</h5>
                <ul className="m-0 p-0">
                  <li onClick={()=>navigation('/open-individual-account')}>Individual</li>
                  <li onClick={()=>navigation('/open-business-account')}>Business</li>
                </ul>
              </div>
            </div>

            <div className="col-md-2  mt-3 mt-sm-3 mt-0 ">
              <div className="listing">
                <h5 className="text-white">Recharge Account</h5>
                <ul className="m-0 p-0">
                <li onClick={()=>navigation('/using-credit-cards')} >Using Credit Cards</li>
                  <li onClick={()=>navigation('/using-your-formal-bank-account')} >Using Your Formal Bank Account</li>
                  <li  onClick={()=>navigation('/qr-scanning')} >Scanning QR</li>
                  <li onClick={()=>navigation('/authorized-stores')}>Authorized Stores</li>
                  <li onClick={()=>navigation('/authorized-street-vendors')}>Authorized Street Vendors</li>
                </ul>
              </div>
            </div>

            <div className="col-md-2 mt-3 mt-sm-3 mt-0 ">
              <div className="listing">
                <h5 className="text-white ">About Us</h5>
                <ul className="m-0 p-0">
                  <li onClick={()=>navigation('/about-us')} >About PayZeus</li>
                  <li onClick={()=>navigation('/our-values')} >Our Values</li>
                  <li  onClick={()=>navigation('/our-vision')} >Our Vision</li>
                  <li onClick={()=>navigation('/our-mission')}>Our Mission</li>
                  <li onClick={()=>navigation('/faq')}>FAQs</li>
                  <li  onClick={()=>navigation("/news")}>News</li>
                  
                  <li onClick={()=>navigation('/feedback')}>Provide Feedback & Ask</li>
                </ul>
              </div>
            </div>
           

            <div className="col-md-4 mt-3 mt-sm-3 mt-0 ">
              <div className="listing">
                <h5 className="text-white">
                  Subscribe to Learn About Our Latest News & Updates.
                </h5>
                <p className="text-white">
                  Stay up to date with our latest news, receive exclusive deals,
                  and more.
                </p>
                <div className="listing-input">
                  <input
                    type="text"
                    placeholder="Subscribe"
                    className="form-control"
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                  />
                  <button onClick={handleSubscribe} >Submit</button>
                </div>
                <ul className="m-0 p-0 social-icons-list">
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <AiFillInstagram />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaYoutube />
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-0 col-md-5"></div>
            <div className="col-7 col-md-3">
              <h6 className="text-white">
                © 2021-2025 PAYZEUS. All Rights Reserved.{" "}
              </h6>
            </div>
            <div className="col-5 col-md-3">
              <div className="footer-term">
                <ul className="m-0 p-0">
                <li onClick={()=>navigation('/terms-and-conditions')} >Terms</li>
                <li onClick={()=>navigation('/privacy')} >Privacy</li>
                <li onClick={()=>navigation('/legal-page')} >Legal</li>
                
                </ul>
              </div>
            </div>
            <div className="col-0 col-md-1"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
